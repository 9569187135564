<template>
  <div>
    <div class="title">
      <div class="title_1" @click="goback">
        <img src="../../assets/zjt.png" alt="" />
        返回
      </div>
      <div class="title_2">调查问卷</div>
    </div>
    <div v-if="flag">
      <div class="pattern">
        <div>
          <span>选择题</span>
        </div>

        <div class="right">
          <span>{{ index }}</span
          >/{{ list.length }}
        </div>
      </div>
      <div class="topic">
        <div class="title1">
          {{ index }}.
          <span v-html="rows.title"></span>
        </div>
        <ul>
          <li
            :class="item.active ? 'active' : ''"
            v-for="(item, inx) in rows.answerList"
            :key="inx"
            @click="selectRow(item)"
          >
            {{ item.flag }}. <span v-html="item.title"></span>
          </li>
        </ul>
        <el-button
          @keyup.enter.native="submit;"
          class="btn"
          type="primary"
          :disabled="select.length == 0"
          @click="submit"
          >{{ index == rows.all ? "完成" : "下一题" }}</el-button
        >
      </div>
    </div>
    <div v-else>
      <div class="grade">
        <div class="grade_title">答题总分</div>
        <div class="grade_zf">{{ totalpoints }}分</div>
        <div class="list" v-for="(item, index) in lists" :key="index">
          <div>{{ item.flag }}</div>
          <div>{{ item.score }}分</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      surveyid: "",
      title: "满意调查度",
      select: [],
      rows: [],
      textid: "",
      list: [],
      index: 1,
      flag: true,
      lists: [],
      totalpoints: "",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
    }),
  },
  methods: {
    ...mapActions(["getsurvey", "getsubAnswer"]),
    goback() {
      this.$router.go(-1);
    },
    submit() {
      if (this.select.length == 0) {
        this.$message({
          message: "请选择一个选项",
          type: "cancel",
        });
        return false;
      }

      ++this.index;
      this.getsubAnswer({
        username: this.userInfo.username,
        class: this.classid, //课程id
        surveyid: this.surveyid, //问卷id
        questionId: this.textid, //题目id
        title: this.rows.title, //标题
        flag: this.select[0], //选项
      }).then((res) => {
        if (res.code == 200) {
          if (this.index == this.list.length + 1) {
            this.$message({
              message: "反馈完成",
            });
            this.flag = false;
            this.lists = res.data.classScore;
            this.totalpoints = res.data.allScore;
            return;
          }
          this.rows = this.list[this.index - 1];
          this.textid = this.list[this.index - 1].id;
          this.select = [];
        }
      });
    },
    selectRow(item) {
      this.rows.answerList = this.rows.answerList.map((res) => {
        res.active = res.flag == item.flag ? true : false;
        return res;
      });
      this.rows.answerList = this.rows.answerList;
      this.select = [item.flag];
    },
  },
  mounted() {
    this.getsurvey({
      username: this.userInfo.username,
      class: this.classid,
    }).then((res) => {
      if (res.code == 200) {
        this.list = res.data.question;
        this.surveyid = res.data.surveyid;
        this.rows = res.data.question[0];
        // this.rows.answerList.forEach((v) => {
        //   e.active = false;
        // });

        this.textid = res.data.question[0].id;
      }
      if (res.code == 1) {
        this.$message({
          message: res.msg,
          type: "cancel",
        });
        this.flag = false;
        this.lists = res.data.classScore;
        this.totalpoints = res.data.allScore;
      }
      if (res.code == 0) {
        this.$router.go(-1);
        this.$message({
          message: res.msg,
          type: "cancel",
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  height: 45px;
  .title_1 {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #676767;
    img {
      margin-right: 5px;
      height: 20px;
      vertical-align: middle;
    }
  }
  .title_2 {
    margin-left: 24px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
.pattern {
  margin-left: 11px;
  margin-top: 31px;
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #137cfb;
  display: flex;
  justify-content: space-between;
  .right {
    color: #000;
    span {
      color: #fc5430;
    }
  }
}
::v-deep .topic {
  margin-left: 11px;

  margin-top: 17px;
  .title1 {
    margin-bottom: 43px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    img {
      width: 350px;
      height: auto;
    }
  }
  ul {
    .active {
      border: 2px solid #137cfb;
      padding: 11px 19px;
    }
    li {
      &:hover {
        border: 2px solid #137cfb;
        padding: 11px 19px;
      }
      img {
        width: 350px;
        height: auto;
      }
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      padding: 13px 21px;
      box-sizing: border-box;
      margin-bottom: 16px;

      background: #fafafa;
      width: 527px;
      // height: 50px;
    }
  }
  .msg {
    padding: 16px 0;
    text-align: left;
    font-size: 18px;
    color: #222222;

    .icon {
      color: #0039ff;
      font-size: 22px;
      margin-right: 5px;
      cursor: pointer;
    }
    span {
      cursor: pointer;
    }
  }
  .answer {
    margin-top: 30px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    span {
      color: #137cfb;
    }
  }
  .btn {
    margin-top: 65px;
    margin-bottom: 500px;
    color: #fff;
    text-align: center;
    width: 235px;
    height: 39px;
    background: #137cfb;
  }
  .arduy {
    pointer-events: none;
    background: #ccc;
  }
}
.grade {
  text-align: center;
  margin-bottom: 50px;
  .grade_title {
    font-size: 20px;
    margin: 30px 0;
  }
  .grade_zf {
    font-size: 20px;
    margin: 30px 0;
  }
  .list {
    margin: 0 auto;
    width: 200px;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
</style>
